import { ACTION_TYPES as CONSOLE_AC } from './_action-types';
import { MUTATION_TYPES as BUYOUT_MT } from 'js-mp/src/vue/store/buyout/_mutation-types';
import { MUTATION_TYPES as CONSOLE_MT } from '../consoleBuyout/_mutation-types';
import { ACTION_TYPES as BUYOUT_AC } from 'js-mp/src/vue/store/buyout/_action-types';
import { apiAxios, URLS } from 'js-mp/src/js/buyout';
import { VUEX_BUYOUT } from 'js-mp/src/vue/store/buyout';
import { buyout } from 'js-mp';
import { store } from '../../main';
import { eds_licence_type, esd_product_id } from '../../config';

export const actions = {
	async [CONSOLE_AC.GET_XBOX_LICENCE]({ commit, dispatch }) {
		let url = `${URLS.XBOX_LICENCE}/${esd_product_id}/${store.state.buyout.userBuyoutCode}`;
		let payload = {
			order_id: store.state.buyout.userBuyoutCode,
			order_item_type: eds_licence_type
		};
		try {
			const response = await apiAxios.post(url, payload);
			if (response.status === 200) {
				dispatch(
					`${VUEX_BUYOUT.NAMESPACE}/${BUYOUT_AC.LOAD_BUYOUT}`,
					{},
					{
						root: true
					}
				);
			}
		} catch (err) {
			if (err.response.status === 400) {
				commit(
					`${VUEX_BUYOUT.NAMESPACE}/${BUYOUT_MT.SET_BUYOUT_ADDITIONAL_INFO_ERRORS}`,
					err.response.data,
					{
						root: true
					}
				);
			}
		}
	},
	async [CONSOLE_AC.LOAD_GAMING_ACCESSORY]({ commit, dispatch }) {
		commit(CONSOLE_MT.SET_ACCESSORIES_DATA_LOADING, true);
		const { response, error } = await dispatch(
			`${VUEX_BUYOUT.NAMESPACE}/${BUYOUT_AC.LOAD_DATA}`,
			`${URLS.GAMING_ACCESSORY}`,
			{
				root: true
			}
		);
		commit(CONSOLE_MT.SET_ACCESSORIES_DATA_LOADING, false);
		if (error) return;
		let accessoriesData = response.data;
		commit(
			CONSOLE_MT.SET_CONTROLLER_DATA,
			accessoriesData.filter(accessories => {
				return accessories.device_type === 'gaming_controller';
			})[0]
		);
		commit(
			CONSOLE_MT.SET_GAME_DATA,
			accessoriesData.filter(accessories => {
				return accessories.device_type === 'gaming_game';
			})[0]
		);
	},
	async [CONSOLE_AC.LOAD_COLORS]({ commit, dispatch }) {
		commit(`${VUEX_BUYOUT.NAMESPACE}/${BUYOUT_MT.SET_LOADING_COLORS}`, true, {
			root: true
		});
		commit(
			`${VUEX_BUYOUT.NAMESPACE}/${BUYOUT_MT.RESET_COLORS}`,
			{},
			{ root: true }
		);
		dispatch(
			`${VUEX_BUYOUT.NAMESPACE}/${BUYOUT_AC.RESET_APPRAISAL}`,
			{},
			{
				root: true
			}
		);
		const { response, error } = await dispatch(
			`${VUEX_BUYOUT.NAMESPACE}/${BUYOUT_AC.LOAD_DATA}`,
			`${URLS.PRODUCT_COLORS}/${buyout.store.state.selectedModel.seo_name}`,
			{ root: true }
		);
		if (error) return;

		commit(`${VUEX_BUYOUT.NAMESPACE}/${BUYOUT_MT.SET_COLORS}`, response.data, {
			root: true
		});
		commit(
			`${VUEX_BUYOUT.NAMESPACE}/${BUYOUT_MT.SET_LOADING_COLORS}`,
			!buyout.store.state.loadingColors,
			{ root: true }
		);
		commit(
			`${VUEX_BUYOUT.NAMESPACE}/${BUYOUT_MT.SET_SELECTED_COLOR}`,
			response.data[0],
			{ root: true }
		);
		commit(`${VUEX_BUYOUT.NAMESPACE}/${BUYOUT_MT.SET_LOADING_COLORS}`, false, {
			root: true
		});
	}
};
