<template>
  <v-app class="xboxApp">
    <transition name="fade-in-out" appear>
      <router-view></router-view>
    </transition>
  </v-app>
</template>

<script>
export default {
  name: 'XboxApp',
}
</script>
