import Vue from 'vue';
import VueRouter from 'vue-router';
import { buyout } from 'js-mp';
const { i18n } = buyout.plugins;
import { store } from '../main';
import { VUEX_BUYOUT } from 'js-mp/src/vue/store/buyout/index';
import { MUTATION_TYPES } from 'js-mp/src/vue/store/buyout/_mutation-types';
import { ACTION_TYPES } from 'js-mp/src/vue/store/buyout/_action-types';

Vue.use(VueRouter);

const routes = [
	{
		path: '/ochrana-osobnich-udaju',
		name: 'privacyPolicy',
		component: () => import('../views/PrivacyPolicy.vue')
	},
	{
		path: '/pravni-informace',
		name: 'legalInformation',
		component: () => import('../views/LegalInformation.vue')
	},
	{
		path: '/podpora',
		name: 'Support',
		component: () => import('../views/Support.vue')
	},
	{
		path: '/partneri',
		name: 'Partners',
		component: () => import('../views/Partners.vue')
	},
	{
		path: '/svoz-uspech',
		name: 'CollectionSuccess',
		component: () => import('../views/BuyoutState.vue'),
		props: {
			isSuccess: true,
			mainText: i18n.t('buyout.BuyoutState.collection_success')
		}
	},
	{
		path: '/svoz-jiz-objednan',
		name: 'CollectionAlreadyOrdered',
		component: () => import('../views/BuyoutState.vue'),
		props: {
			isSuccess: true,
			mainText: i18n.t('buyout.BuyoutState.collection_already_ordered')
		}
	},
	{
		path: '/svoz-chyba',
		name: 'CollectionError',
		component: () => import('../views/BuyoutState.vue'),
		props: {
			isSuccess: false,
			mainText: i18n.t('buyout.BuyoutState.something_went_wrong')
		}
	},
	{
		path: '/preceneni-prijato',
		name: 'RevaluationSuccess',
		component: () => import('../views/BuyoutState.vue'),
		props: {
			isSuccess: true,
			mainText: i18n.t('buyout.Revaluation.revaluation_success')
		}
	},
	{
		path: '/preceneni-odmitnuto',
		name: 'RevaluationDenied',
		component: () => import('../views/BuyoutState.vue'),
		props: {
			isSuccess: false,
			mainText: i18n.t('buyout.Revaluation.revaluation_denied')
		}
	},
	{
		path: '/preceneni-uzavreno',
		name: 'RevaluationClosed',
		component: () => import('../views/BuyoutState.vue'),
		props: {
			isSuccess: false,
			mainText: i18n.t('buyout.Revaluation.revaluation_closed')
		}
	},
	{
		path: '/gamepass/:userBuyoutCode',
		name: 'gamepass',
		component: () => import('../views/GamePassPage.vue'),
		beforeEnter: async (to, from, next) => {
			if (!to.params.userBuyoutCode) next('/');
			if (store.state.userBuyoutCode === to.params.userBuyoutCode) next();
			else {
				store.commit(
					`${VUEX_BUYOUT.NAMESPACE}/${MUTATION_TYPES.SET_IS_BUYOUT_ONLINE}`,
					true
				);
				store.commit(
					`${VUEX_BUYOUT.NAMESPACE}/${MUTATION_TYPES.SET_IS_REGISTRATION}`,
					false
				);
				store.commit(
					`${VUEX_BUYOUT.NAMESPACE}/${MUTATION_TYPES.SET_USER_BUYOUT_CODE}`,
					to.params.userBuyoutCode
				);
			}
			next();
		}
	},
  {
		path: '/vykup/:userBuyoutCode',
		name: 'buyout',
		component: () => import('../views/Home.vue'),
		beforeEnter: (to, from, next) => {
			if (!to.params.userBuyoutCode) next('/');
			if (store.state.userBuyoutCode === to.params.userBuyoutCode)
				next();
			else {
				store.commit(
					`${VUEX_BUYOUT.NAMESPACE}/${MUTATION_TYPES.SET_IS_BUYOUT_ONLINE}`,
					true
				);
				store.commit(
					`${VUEX_BUYOUT.NAMESPACE}/${MUTATION_TYPES.SET_IS_REGISTRATION}`,
					false
				);
        store.commit(
					`${VUEX_BUYOUT.NAMESPACE}/${MUTATION_TYPES.SET_IS_LOGGED_IN}`,
					false
				);
        store.commit(
					`${VUEX_BUYOUT.NAMESPACE}/${MUTATION_TYPES.SET_BUYOUT_LOAD_ERROR}`,
					null
				);
				store.commit(
					`${VUEX_BUYOUT.NAMESPACE}/${MUTATION_TYPES.SET_USER_BUYOUT_CODE}`,
					to.params.userBuyoutCode
				);
        store.dispatch(
          `${VUEX_BUYOUT.NAMESPACE}/${ACTION_TYPES.LOAD_STATE_TYPES}`
        );
			}
			next();
		}
	},
	{
		path: '/:category?/:brand?/:model?/:color?/:quality?',
		name: 'home',
		component: () => import('../views/Home.vue'),
		beforeEnter: (to, from, next) => {
			if (to.params.category && to.params.category === 'vykup') next('/');
			else {
				next();
			}
		}
	}
];

const router = new VueRouter({
	mode: 'history',
	routes
});

export default router;
