import { MUTATION_TYPES } from './_mutation-types';

export const mutations = {
	[MUTATION_TYPES.SET_USABLE_CONSOLES](state, val) {
		state.usableConsoles = val;
	},
	[MUTATION_TYPES.SET_CONTROLLERS_QUANTITY](state, val) {
		state.controllersQuantity = val;
	},
	[MUTATION_TYPES.SET_GAMES_QUANTITY](state, val) {
		state.gamesQuantity = val;
	},
	[MUTATION_TYPES.SET_CONTACT_FORM_IS_VALID](state, val) {
		state.contactFormIsValid = val;
	},
	[MUTATION_TYPES.SET_GAME_DATA](state, val) {
		state.gameData = val;
	},
	[MUTATION_TYPES.SET_CONTROLLER_DATA](state, val) {
		state.controllerData = val;
	},
	[MUTATION_TYPES.SET_IS_QUANTITY_FORM_VALID](state, val) {
		state.quantityFormIsValid = val;
	},
	[MUTATION_TYPES.SET_ACCESSORIES_DATA_LOADING](state, val) {
		state.accessoriesDataLoading = val;
	}
};
