export const MUTATION_TYPES = {
	SET_USABLE_CONSOLES: 'setUsableConsoles',
	SET_SELECTED_CONSOLE: 'setSelectedConsole',
	SET_CONTROLLERS_QUANTITY: 'setControllersQuantity',
	SET_GAMES_QUANTITY: 'setGamesQuantity',
	SET_CONTACT_FORM_IS_VALID: 'setContactFormIsValid',
	SET_GAME_DATA: 'setGameData',
	SET_CONTROLLER_DATA: 'setControllerData',
	SET_IS_QUANTITY_FORM_VALID: 'setIsQuantityFormValid',
	SET_ACCESSORIES_DATA_LOADING: 'setAccessoriesDataLoading'
};
