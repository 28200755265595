import { GETTER_TYPES } from './_getter-types';
import { buyout } from 'js-mp';

export const getters = {
	[GETTER_TYPES.GET_CONTROLLERS_QUANTITY](state) {
		return state.controllersQuantity;
	},
	[GETTER_TYPES.GET_GAMES_QUANTITY](state) {
		return state.gamesQuantity;
	},
	[GETTER_TYPES.GET_BUYOUT_PAYLOAD](state) {

		let buyout_device = buyout.store.state.contractForm.buyout_device;

		if ('new_device_imei' in buyout_device)
			delete buyout_device['new_device_imei'];
		let accessoriesData = [];
		if (state.controllerData)
			accessoriesData.push({
				boss_code: state.controllerData.boss_code,
				quantity: parseInt(state.controllersQuantity),
				device_type: state.controllerData.device_type
			});
		if (state.gameData)
			accessoriesData.push({
				boss_code: state.gameData.boss_code,
				quantity: parseInt(state.gamesQuantity),
				device_type: state.gameData.device_type
			});
		return {
			user_buyout_code: '',
			appraisal_version: buyout.store.state.appraisal.version,
			device_identifier: buyout.store.state.selectedColor.seo_name,
			device_type: buyout.store.state.selectedCategory.seo_name,
			note: buyout_device.note,
			buyout_device: buyout_device,
			customer_data: buyout.store.state.contractForm.customer_data,
			partner: buyout.store.state.selectedPartner,
			consent_to_marketing: buyout.store.state.consentToMarketing,
			accessories: accessoriesData
		};
	},
	[GETTER_TYPES.GET_IS_CUSTOMER_DATA_VALID](state) {
		return (
			state.contactFormIsValid &&
			state.controllersQuantity >= 0 &&
			state.gamesQuantity >= 0
		);
	},
	[GETTER_TYPES.GET_IS_QUANTITY_FORM_VALID](state) {
		return state.quantityFormIsValid;
	},
	[GETTER_TYPES.GET_IS_ACCESSORIES_DATA_LOADING](state) {
		return state.accessoriesDataLoading;
	}
};
