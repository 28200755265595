import { allowed_date_formats } from './config';

export const overrides = {
	cs: {
		buyout: {
			give_registration_number: 'Zadej číslo tvojí registrace',
			AdditionalInfoForm: {
				new_selected_model: 'Model nového Xboxu',
				select_new_model_name: 'Vyber model svého nově zakoupeného Xboxu',
				new_model_partner_sub_text:
					'Vyber prodejce u kterého byl nový Xbox zakoupen'
			},
			deviceFinder: {
				chose_type: 'Vyber typ',
				chose_brand: 'Vyber značku',
				chose_model: 'Vyber model'
			},
			find_price_of_device: 'Zjisti hodnotu svého zařízení',
			buyout_price: 'Tvoje výkupní cena',
			thankYou: {
				preSale: {
					title:
						'Informativní nacenění bylo odesláno na Tvou e-mailovou adresu pod číslem <b>{0}</b>.'
				}
			}
		},
		console: {
			new: 'Vyměň',
			most_preferably: ' starou za novou',
			used_console: 'Použitého',
			the_right_time: 'Ten pravý čas',
			for_upgrade: 'na upgrade',
			do_you_desire_for_a_new_console_but_you_are_short_on_money:
				'Toužíš po nové konzoli, ale chybí ti peníze ?',
			we_will_buyout_your_old_sony_playstation_console_money_will_be_send_to_your_account_and_the_new_can_be_yours:
				'Starou konzoli Sony PlayStation od Tebe vykoupíme,       peníze pošleme na účet a nová může být Tvoje!',
			copyright_text:
				'Veškerý obsah, všechny herní tituly, ochranné známky a / nebo úpravy, ochranné známky, umělecká díla a související ilustrace jsou ochranné známky nebo materiály chráněné autorskými právy příslušných vlastníků. Všechna práva vyhrazena. ',
			take_opportunity_to_contact_a_professional_purchase_assistant:
				'Využíj možnost kontaktování profesionálním asistentem výkupu',
			xbox_game_pass_text:
				'Objev knihovnu s více než 100 vysoce kvalitními hrami. Využij výhod členství Xbox Live Gold, EA Play a hraj napříč zařízeními z cloudu po registraci předplatného Xbox Game Pass Ultimate.',
			xbox_membership_text:
				'Členství EA Play je nyní zahrnuté v předplatném Xbox Game Pass for PC a Ultimate, a to bez dalšího příplatku. S EA Play získáš přístup ke kolekci nejoblíbenějších her od EA, odměnám ve hrách a zkušebním verzím vybraných nových her.',
			join_now: 'Přidej se hned',
			main_claim:
				'Toužíš po novém XBOXU? Využij možnost prodat nám své použité zařízení* a získat tak nový Xbox za bezkonkurenční cenu! Jako bonus získáš navíc měsíční předplatné Xbox Game Pass.',
			support_main_claim: '* telefon/tablet/chytré hodinky/herní konzole',
			where_to_buy_xbox: 'Kde koupit Xbox',
			sale_of_used_consoles: 'Prodej použitých konzolí',
			where_you_can_buy_new_console:
				'U kterých můžeš zakoupit konzole a příslušenství značky Xbox',
			your_xbox: 'Tvůj Xbox',
			game_pass_activation_key: 'Game Pass aktivační kód:',
			login: 'Přihlásit se',
			registration_code_for_login: 'Pro přihlášení zadej kód své registrace. ',
			back_to_login: 'Zpět na přihlášení',
			generate: 'Vygenerovat',
			generate_your_xbox_activation_key:
				'Vygeneruj si svůj Xbox Game Pass aktivační klíč',
			buyout_not_found: 'Výkup nebyl nalezen'
		},
		validators: {
			required: 'Toto pole je povinné.',
			is_number: 'Toto pole může obsahovat pouze číslice.',
			invalid_bank_account_number: 'Neplatné číslo účtu.',
			invalid_bank_code: 'Neplatný kód banky.',
			min_allowed_value: 'Nejnižší povolená hodnota je: {minValue}.',
			invalid_email: 'Neplatný email',
			valid_post_code: 'Hodnota musí být platné PSČ',
			is_newer_date_valid: 'Datum musí být pozdější než dnešní',
			is_date_valid:
				'Chybný formát data. Použijte prosím jeden z těchto formátů: ' +
				allowed_date_formats.join(', ')
		}
	}
};
